import axios from 'axios'
import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { Button, Card, CardBody } from 'reactstrap'
import envelopeSvg from "../../assets/img/theme/envelope.svg"
import { t } from 'multi-language/multi-lang.service'
import './login-pages.scss'
const DISABLE_RESEND_TIME = 10

function ActivateAccount({ email }) {
  const [disableResendTime, setDisableResendTime] = useState(0)
  const intervalRef = useRef()
  
  useEffect(() => {
    resendEmail()
  }, [])

  const disableResend = () => {
    setDisableResendTime(DISABLE_RESEND_TIME)
    if(intervalRef.current) return
    intervalRef.current = setInterval(() => {
      setDisableResendTime(prev => {
        if(prev > 0) return prev-1
        clearInterval(intervalRef.current)
        intervalRef.current = null
        return 0
      })
    }, 1000);
  }

  const resendEmail = async () => {
    if(disableResendTime) return
    try {
      disableResend()
      await axios.post(`${process.env.REACT_APP_API_URL}/app/user/resend-verification-email`, { username: email })
      console.log('activate email sent')
    } catch (err) {
      console.log('err', err)
    }
  }

  return (
    <div>
      <Card id='activate-account' className="bg-secondary activate-content shadow border-0">
        <CardBody className="px-lg-5 py-lg-5 activate-text">
          <img className='pb-3' width={70} src={envelopeSvg} alt='send' />
          <p className=''>
            {t('doctor.registration.activate_first')}:
            <strong className='mt-2 mb-2 d-block'>{email}</strong>
          </p>
          <p>
            {t('doctor.registration.activate_second')}
            {' '}
            <span className={`resend-action ${disableResendTime > 0 && 'resend-disabled'}`} onClick={resendEmail}>
              {t('doctor.registration.resend')}
              {disableResendTime > 0 && ` (${disableResendTime} ${t('units.sec')})`}
            </span>.
          </p>
          <hr />
          <div className='activate-btns'>
            <Link to={'/login'}><Button id='resend-mail' className='bg-blue text-white'>{t('doctor.registration.conitnue')}</Button></Link>
          </div>
        </CardBody>
      </Card>
    </div>
  )
}

export default ActivateAccount