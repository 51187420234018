import { setUserToState } from 'store/actions'
import axios from 'axios'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import MonthlyCheckbox from '../../../assets/img/icons/checkbox.svg'
import { t } from 'multi-language/multi-lang.service'

export default function EmailPreferences({setUser}) {
    const { user } = useSelector(state => state)
    const[checked,setChecked] = useState()
    const dispatch = useDispatch()
    const handleCheck = ()=>{
        setChecked(!checked)
    }

    useEffect( () => {
        if(!user.jwtToken)return
        setChecked(user.data.statusSendEmails)
    },[user])

    const updateUser = (status) =>{
       dispatch( setUserToState({...user,data:{...user.data,statusSendEmails:status}}))
          setUser({...user,data:{...user.data,statusSendEmails:status}})
    }

    const handleSave = async() => {     
                if(!checked){
                    await axios.post(`${process.env.REACT_APP_API_URL}/app/user/email_preferences`,{userId: user.data.userId, subscribe: checked},{ headers: {'Authorization': `Bearer ${user.jwtToken}`} })
                    updateUser(checked)
                }
    }

    const handleResubscribe = async () =>{
            setChecked(!checked)
            await axios.post(`${process.env.REACT_APP_API_URL}/app/user/email_preferences`,{userId: user.data.userId, subscribe: true},{ headers: {'Authorization': `Bearer ${user.jwtToken}`} })
            updateUser(true)
        }
    return (
        <Container id='email-preferences' fluid>
            <div className="profile-content-container">
                { user.data?.statusSendEmails ? 
             <div>
              <div style={{width:812,height: 99, marginTop:30,marginLeft:30,color:'#053F7F',fontSize:20}}>
                  {t('pages.email_preferences.desc')}
              </div>
              <div style={{width:'63%',height:20,borderTop:'1px solid #00000029',marginTop:'2%', marginLeft:20}}></div>
                <div style={{display:'flex',flexDirection:'row'}}>
                {
                  checked ? <div id='uncheck-monthly' onClick={handleCheck}>
                    <img  style={{cursor:'pointer',width:24,height:24,marginLeft:30}} src={MonthlyCheckbox}/>
                    </div>
                  : <div id='check-monthly' onClick={handleCheck} style={{cursor:'pointer',width:24,height:24,marginLeft:30,border:'1px solid gray',borderRadius:5}}> </div>
                }
               <div style={{marginLeft:10,fontSize:17,fontWeight:'400',color:'black'}}>{t('pages.email_preferences.monthly')}</div>
               </div>
               <div style={{marginLeft:30,marginTop:20}}>
                   {t('pages.email_preferences.monthly_desc')}
               </div>
                <div>
                   <div style={{width:'63%',height:20,borderTop:'1px solid #00000029',marginTop:'2%',marginLeft:20}}></div>
                   <div id='submit-unsubscribe' onClick={handleSave} style={{cursor:'pointer',height:30,display:'flex',justifyContent:'center',alignItems:'center',width:200,marginLeft:'30%',marginTop:20,color:'white',backgroundColor:'#3873AE',borderRadius:50,}} >{t('pages.email_preferences.submit')}</div>
               </div>
               </div>
                :
                <div>
                    <div style={{width:812,marginTop:30,marginLeft:30,color:'#053F7F',fontSize:20}}>
                        {t('pages.email_preferences.success')}
                    </div>
                      <div style={{width:'63%',height:20,borderTop:'1px solid #00000029',marginTop:'2%',marginLeft:20}}></div>
                      <div style={{fontSize:20,color:'#000000',marginLeft:30,marginBottom:40,opacity:0.9}}>
                        {t('pages.email_preferences.removed')} <span style={{color:'#000000',fontWeight:800}}> {t('pages.email_preferences.monthly')}</span>
                      </div>
                      
                      <span style={{marginLeft:30,fontSize:20,color:'#000000',opacity:0.9}}>{t('pages.email_preferences.resubscribe')}</span>
                      <div id='resubscribe-email' onClick={handleResubscribe} style={{cursor:'pointer',height:30,display:'flex',justifyContent:'center',alignItems:'center',width:200,marginLeft:'1.5%',marginTop:20,color:'white',backgroundColor:'#3873AE',borderRadius:50,}} >{t('pages.email_preferences.subscribe')}</div>
                      

                </div>
                  
                }
              
            </div>
        </Container>
    )
}