import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Container } from 'reactstrap'
import './account-deletion.css'
import axios from 'axios'
import ConfirmModal from "components/Modals/confirm-modal";
import { t } from 'multi-language/multi-lang.service'


export default function AccountDeletion() {
  const [reason, setReason] = useState('')
  const [showPopUpConfirm, setShowPopUpConfirm] = useState(false)
  const { user } = useSelector(state => state)
  const handleChange = (event) => {
    setReason(event.target.value)
  }

  const sendRequest = async () => {
    await axios.post(`${process.env.REACT_APP_API_URL}/app/user/delete-account`, { description: reason }, { headers: { 'Authorization': `Bearer ${user.jwtToken}` } })
    setShowPopUpConfirm(false)
    setReason('')
  }
  return (
    <Container fluid>
      <div id='delete-account'>
        <div className="profile-content-container">
          {showPopUpConfirm &&
            <ConfirmModal
              text={t('pages.account_deletion.confirm')}
              onConfirm={sendRequest}
              onCancel={setShowPopUpConfirm}
              confirmButtonText={t('general_words.send')}
              cancelButtonText={t('general_words.cancel')}
            />
          }

          <div className='title'>{t('pages.account_deletion.page_name')}</div>
          <div className='line'></div>
          <div className='description'>{t('pages.account_deletion.warning')}</div>
          <div className='warning'>{t('general_words.warning')}!</div>
          <div className='after-delete'>{t('pages.account_deletion.after_text')}</div>
          <div className='input-title'>{t('pages.account_deletion.reason')}</div>
          <input id='input-delete' placeholder={`(${t('doctor.registration.optional')})`} onChange={handleChange} />
          <div className='support'>{t('pages.account_deletion.contact')}</div>
          <div className='line long-line'></div>
          <div id='action-delete' className='send-request' onClick={() => setShowPopUpConfirm(true)}>{t('pages.physicians.request')}</div>
        </div>
      </div>

    </Container>
  )
}