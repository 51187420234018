import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { Button, Input, InputGroup, Row, Col } from "reactstrap";
import { VALID_EMAIL_REGEX, VALID_PASSWORD_REGEX } from 'consts';
import Show from '../../assets/img/icons/Show.svg'
import Hide from '../../assets/img/icons/Hide.svg'
import PasswordRestrictions from './password-restrictions';
import CaptchaComponent from './captcha-component';
import { t } from 'multi-language/multi-lang.service';

export default function CredentialsScreen({ onSubmit, errMessage, isRegister, displayCaptcha }) {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [confirmPassword, setConfirmPassword] = useState(null)
  const [captchaValue, setCaptchaValue] = useState('')
  const [errorConfirm, setErrorConfirm] = useState('')
  const [showPassword, setShowPasssword] = useState(false)
  const [showConfirmPassword, setShowConfirmPasssword] = useState(false)

  const handleEnter = (event) => {
    event.preventDefault();
    if (event.key === 'Enter' && !isDisabled()) {
      handleLogin()
    }
  }
  useEffect(() => {
    document.addEventListener("keyup", handleEnter);
    return () => document.removeEventListener("keyup", handleEnter);
  }, [])

  useEffect(() => {
    if (email && !VALID_EMAIL_REGEX.test(email)) {
      return setErrorConfirm(t('pages.login.errors.invalid_mail'))
    }
    if (password && !VALID_PASSWORD_REGEX.test(password)) {
      return setErrorConfirm(t('pages.login.errors.invalid_pass'))
    }
    if (confirmPassword && confirmPassword !== password) {
      return setErrorConfirm(t('pages.login.errors.no_match'))
    }
    setErrorConfirm('')
  }, [password, confirmPassword, email])

  const handleLogin = async () => {
    onSubmit(email, password, captchaValue)
  }

  const handeShowPassword = () => {
    setShowPasssword(!showPassword)
  }

  const handeShowConfirmPassword = () => {
    setShowConfirmPasssword(!showConfirmPassword)
  }

  const isDisabled = () => {
    if(!email || !password || errorConfirm) return true
    if(isRegister && !confirmPassword) return true
    if(!isRegister && displayCaptcha && !captchaValue) return true
    return false
  }
  return (
    <div id='credentials-content' className="credentials-content" >
      <Row className="mb-3">
        <Col className='label-wrap' xs="4">
          <div className={`label-text ${isRegister && 'register'}`} >
            {t('personal_info.email')}
          </div>
        </Col>
        <Col className='ps-1'>
          <InputGroup className="input-group-alternative">
            <div className='input-group-append'>
              <div className='input-group-text'>
                <i className="ni ni-email-83" />
              </div>
            </div>
            <Input
              id="input-email"
              value={email}
              onChange={e => setEmail(e.target.value)}
              placeholder={t('personal_info.email')}
              type="email"
              autoFocus
              autoComplete="new-email"
            />
          </InputGroup>
        </Col>
      </Row>

      <Row className="mb-3">
        <Col className='label-wrap' xs="4">
          <div className={`label-text ${isRegister && 'register'}`}>
            {isRegister && <span>{t('general_words.create')}</span>} {t('personal_info.password')}  
          </div>

        </Col>
        <Col className='ps-1'>
          <InputGroup className="input-group-alternative">
            <div className='input-group-append'>
              <div className='input-group-text'>
                <i className="ni ni-lock-circle-open" />
              </div>
            </div>
            <Input
              id="input-password"
              value={password}
              onChange={e => setPassword(e.target.value)}
              placeholder={t('personal_info.password')}
              type={showPassword ? "text" : "password"}
              autoComplete="new-password"
            />
            <div className='show-password' onClick={handeShowPassword}>
              <img alt='show' src={showPassword ? Hide : Show} width={25} />
            </div>
          </InputGroup>
        </Col>
      </Row>
      {isRegister && <>
        <Row className="mb-3">
          <Col className='label-wrap' xs="4">
            <div className={`label-text ${isRegister && 'register'}`}>
              {t('pages.passwords.confirm')}
            </div>

          </Col>
          <Col className='ps-1'>
            <InputGroup className="input-group-alternative">
              <div className='input-group-append'>
                <div className='input-group-text'>
                  <i className="ni ni-lock-circle-open" />
                </div>
              </div>
              <Input
                id="confirm-password"
                value={confirmPassword}
                onChange={e => setConfirmPassword(e.target.value)}
                placeholder={t('pages.passwords.confirm')}
                type={showConfirmPassword ? "text" : "password"}
                autoComplete="new-password"
              />
              <div className='show-password' onClick={handeShowConfirmPassword}>
                <img alt='confirm show password' src={showConfirmPassword ? Hide : Show} width={25} />
              </div>
            </InputGroup>
          </Col>
        </Row>
        <Row>
          <Col className='label-wrap' xs="4"></Col>
          <Col><PasswordRestrictions /></Col>
        </Row>
        <hr />
      </>}
      {(!isRegister && displayCaptcha) && <div className='pb-2 d-flex justify-content-end'><CaptchaComponent onCaptchaChange={setCaptchaValue} /></div>}
      {!isRegister &&
        <Row className="mt-3">
          <Col xs="4"></Col>
          <Col>
            <Link to="/auth/forgot password" className="text-link text-center">
              <small>{t('pages.login.forgot_pass')}?</small>
            </Link>
          </Col>
        </Row>
      }
      <Row>
        <Col>
          <small id='error-login' className="text-danger">{errMessage || errorConfirm}</small>
        </Col>
        <Col xl="auto" md="auto" sm="auto" xs="auto">
          <Button id='login-action' disabled={isDisabled()} onClick={handleLogin} className="bg-blue text-white" type="button">
            {isRegister ? t('general_words.create') : t('pages.login.sign')}
          </Button>
        </Col>
      </Row>
    </div>
  )
}
