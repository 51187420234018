import { t } from 'multi-language/multi-lang.service'
import React from 'react'
import { Container } from 'reactstrap'

export default function Subscription() {
    return (
        <Container fluid>
            <div className="profile-content-container">
                {t('pages.subscription.page_name')}
            </div>
        </Container>
    )
}
