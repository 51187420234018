import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import MedicalQuestionModal from 'components/Modals/MedicalQuestion'
import { renderMedicalQuestionAnswer as renderAnswer } from 'utils/formaters'
import { MEDICAL_QUESTIONS_CONDITAIONS as conditions } from 'consts'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { setUserToState, updatePatients } from 'store/actions'
import { getRefreshedUserData } from 'api/api-manager'
import PencilIcon from '../../../assets/img/icons/Edit.svg'
import { t } from 'multi-language/multi-lang.service'

export default function MedicalInfo({ patient, jwtToken, role = 'patient' }) {
    const stateUser = useSelector(state => state.user.data)
    const user = role === 'doctor' ? patient : stateUser
    const initialData = setupQuestions(user)
    const [data, setData] = useState(initialData)
    const [selectedQuestion, setSelectedQuestion] = useState()
    const dispatch = useDispatch()

    useEffect( () => {
        // i think this function is unnecessary but it was asked by Menni - it will reload patient data because maybe the doctor changed patients data
        if (role === 'doctor') return 
        getRefreshedUserData()
    },[role])

    function setupQuestions(user){
        let init = {}
        conditions.forEach((condition, i) => {
            init[condition] = { key: i + 1, answer: user[`question${i + 1}`] }})
        return init
    }

    const sendMailToPatient = (condition, answer) => {
        const oldAnswer = renderAnswer(data[condition], data[condition].answer)
        try {
            axios.post(`${process.env.REACT_APP_API_URL}/app/user/update-patient-medical-question`,
                {
                    doctorName: `${stateUser.firstName} ${stateUser.lastName}`,
                    patientName: `${patient.firstName} ${patient.lastName}`,
                    medicalQuestion: condition,
                    oldAnswer: oldAnswer,
                    newAnswer: answer,
                    patientId: patient.id
                },
                { headers: { 'Authorization': `Bearer ${jwtToken}`, accessToken: user.accessToken } }
            )
        } catch (err) {
            console.log('mail not sent', err)
        }

    }

    const handleSave = (condition, answer) => {
        const questionNumber = initialData[condition].key
        if (role === 'doctor') {
            sendMailToPatient(condition, renderAnswer(condition, answer))
        }
        try {
            axios.post(
                `${process.env.REACT_APP_API_URL}/app/${role}/update-medical-question-answer`,
                {
                    patientId: user.id,
                    questionNumber,
                    answerText: answer,
                },
                { headers: { 'Authorization': `Bearer ${jwtToken}`, accessToken: user.accessToken } }
                );
            const updatedPatient = { ...user, [`question${questionNumber}`]: answer }
            setData(setupQuestions(updatedPatient))
            if (role === 'doctor'){
                dispatch(updatePatients(updatedPatient))
                return setSelectedQuestion(null)
            }
            const updatedUser = {data: updatedPatient, type: 'patient', jwtToken}
            dispatch ( setUserToState(updatedUser) )
            Cookies.set('user', JSON.stringify(updatedUser))
        } 
        catch (err) {
            console.log('error updating question', err)
        }
        setSelectedQuestion()
    }
    return (
        <div id='medical-questions'>
            <MedicalQuestionModal data={selectedQuestion} toggleModal={setSelectedQuestion} onSave={handleSave} />
            <Container fluid>
                <div className="profile-content-container">
                    <div style={{ marginLeft: '55px', marginTop: '40px', marginBottom: '40px' }}>
                        <Row className="info-row-header" >
                            <Col >{t('medical_information.title')}</Col>
                            <Col >{t('general_words.status')}</Col>
                            <Col xl="1" >{t('general_words.edit')}</Col>
                        </Row>
                        {Object.keys(data).map(condition =>
                            <Row key={condition} className="info-row" >
                                <Col>{t(`medical_information.questions.${condition}`)}</Col>
                                <Col> {renderAnswer(condition, data[condition]?.answer)} </Col>
                                <Col xl="1" >
                                    <img alt={t('general_words.edit')}
                                        id={`edit-question-${condition}`}
                                        src={PencilIcon}
                                        onClick={() => setSelectedQuestion({ condition, value: data[condition] })}
                                        width="20px"
                                        style={{ cursor: 'pointer' }} />
                                </Col>
                            </Row>
                        )}
                    </div>
                    {user.type === 'doctor' ?
                        <div style={{ marginLeft: 57, color: '#1A1A1A', fontSize: 20 }}>* {t('medical_information.user_reported')}</div>
                        : null
                    }
                </div>
            </Container>
        </div>
    )
}