/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { addVersion, getCountriesAndConfig, inactiveVersion } from 'api/admin-api'
import { useSelector } from 'react-redux'
import Select from 'react-select'

function VersionsComponent() {
  const { countries, versions } = useSelector(state => state)
  const [mapVersions, setMapVersions] = useState({})
  const [newVersions, setNewVersions] = useState({})
  const [selectedVersions, setSelectedVersions] = useState({})
  const [isEdit, setIsEdit] = useState()

  const fetchData = async () => {
    if (!versions.length) await getCountriesAndConfig()
  }

  useEffect(() => {
    fetchData()
  }, [])

  useEffect(() => {
    if (!versions.length) return
    const tempMapVersions = {}
    versions.forEach(row => {
      const { type, os, release } = row;

      tempMapVersions[type] = tempMapVersions[type] || {};
      tempMapVersions[type][os] = tempMapVersions[type][os] || {};
      tempMapVersions[type][os][release] = tempMapVersions[type][os][release] || [];

      if (row.active) tempMapVersions[type][os][release].push(row);
    });
    setMapVersions(tempMapVersions)
  }, [versions])

  const renderVersionCountries = (type, os) => {
    const selectedV = selectedVersions?.[type]?.[os]
    const versionRows = mapVersions?.[type]?.[os]?.[selectedV]
    const versionRowsCountries = isEdit ? [newVersions?.[type]?.[os]?.country] : versionRows?.map(c => c.country)
    const options = countries?.map((c) => { return { value: c.code, label: c.name, id: c.id } })
    options.unshift({ value: null, label: 'All countries', id: 99999 })
    const selectedCountries = options?.filter(c => versionRowsCountries?.includes(c.value))
    return (
      <Select options={options}
        isDisabled={false}
        isClearable={false}
        placeholder={!selectedCountries.length ? isEdit ? 'Select...' : 'NOT ACTIVE' : ''}
        isMulti={isEdit ? false : true}
        value={selectedCountries}
        onChange={(selectedElements) => {
          isEdit ? updateNewVersions(type, os, 'country', selectedElements.value) : handleSelectCountries(type, os, selectedV, selectedElements)
        }}
      />
    )
  }

  const handleSelectCountries = async(type, os, release, newSelectedCountries) => {
    const oldSelectedCountries = mapVersions?.[type]?.[os]?.[release]

    const mapNewSelected = {}
    const mapOldSelected = {}
    newSelectedCountries.forEach(c => {
        mapNewSelected[c.value] = c
    });
    oldSelectedCountries.forEach(c => {
        mapOldSelected[c.country] = c
    });

    if(newSelectedCountries > oldSelectedCountries){//added country
        let countryToAdd = newSelectedCountries.find(c => !mapOldSelected[c.value])
        let confirm = window.confirm(`Are you shure you want to add ${countryToAdd.label} to ${type} version: ${release}? ${countryToAdd.value ? `\nbe noticed that choosing specific country means that users from this country will receive the highest ${type} by their country even if it will be a higher ${type} available for all countries` : ''}`)
        if(confirm) addVersion({ type, os, release, country: countryToAdd.value })
    } else {//deleted country
        let countryToDelete = oldSelectedCountries.find(c => !mapNewSelected[c.country])
        if(!countryToDelete.country) return alert('you can not remove all countries from the version, because it will stay without an active version. \nif you want you can create a new version')
        let confirm = window.confirm(`Are you shure you want to remove ${countryToDelete.country} from ${type} version: ${release}?\nbe noticed that each country needs to be linked to at list one version, so after removing attach it to another version instead`)
        if(confirm) inactiveVersion({ type, os, release, country: countryToDelete.country })
    }
  }

  const handleAddVersion = (type, os) => {
    const newVersion = newVersions?.[type]?.[os]
    setNewVersions({})
    setIsEdit(false)
    addVersion({ type, os, ...newVersion })
  }

  const updateNewVersions = async (type, os, updateField, value) => {
    setNewVersions(prev => ({ ...prev, [type]: { ...prev?.[type], [os]: { ...prev?.[type]?.[os], [updateField]: value } } }))
  }

  const renderReleaseEditInput = (type, os) => {
    return <input onChange={(e) => updateNewVersions(type, os, 'release', e.target.value)} value={newVersions?.[type]?.[os]?.release} className='form-control' placeholder='1.0.0' />
  }

  const renderReleasesInput = (type, os) => {
    if (isEdit) {
      return renderReleaseEditInput(type, os)
    }
    return renderReleasesSelect(type, os)
  }

  const isAddVersionDisabled = (type, os) => {
    return !newVersions?.[type]?.[os]?.release
      || (!newVersions?.[type]?.[os]?.country && newVersions?.[type]?.[os]?.country !== null)
  }

  const renderReleasesSelect = (type, os) => {
    const versions = mapVersions[type][os]
    const options = Object.keys(versions)?.map((v) => {
      const isActive = versions[v].length
      const label = isActive ? v : `${v} (not active)`
      return { value: v, label, id: v, isDisabled: !isActive }
    })
    return (
      <Select options={options}
        isOptionDisabled={(option) => option.isDisabled}
        isClearable={false}
        isMulti={false}
        onChange={(selectedElements) => {
          setSelectedVersions(prev => ({ ...prev, [type]: { ...prev[type], [os]: selectedElements.value } }))
        }}
      />
    )
  }

  return (
    <div className='card-body'>
      <div className='wrap-config'>
        <div className='list-group'>
          <button onClick={() => setIsEdit(!isEdit)} className='btn btn-primary mb-3'>Add Version</button>
          {
            Object.keys(mapVersions)?.map(type => (
              Object.keys(mapVersions[type])?.map(os => (
                <div key={type + os} className='list-group-item d-flex justify-content-between countries-row'>
                  <p className='col mb-0 row align-items-center'><strong className='pr-2'>Type:</strong><span>{type}</span></p>
                  <p className='col-2 mb-0 ml-1 row align-items-center'><strong className='pr-2'>OS:</strong><span>{os}</span></p>
                  <p className='col-3 mb-0 row align-items-center'><strong className='pr-2'>Releases:</strong><strong className='w-50'>{renderReleasesInput(type, os)}</strong></p>
                  <p className='col-4 mb-0 row align-items-center'><strong className='pr-2'>Countries:</strong><strong className='w-75'>{renderVersionCountries(type, os)}</strong></p>
                  {isEdit && <p className='col-1 mb-0 row align-items-center'><button onClick={() => handleAddVersion(type, os)} disabled={isAddVersionDisabled(type, os)} className='btn btn-primary'>Add</button></p>}
                </div>
              )
              )))
          }
        </div>
      </div>
    </div>

  )
}

export default VersionsComponent