import React, { useEffect, useState } from 'react'
import ReactSelect from 'react-select'
import areaCodesJson from './area-codes.json'
import './phone-number-input.scss'

function PhoneNumberInput({phoneNumber, setPhoneNumber, countryCode}) {
    const { _areaCode, _phoneNum } = getOrderedPhoneNumber(phoneNumber, countryCode)
    const [areaCode, setAreaCode] = useState(_areaCode)
    const [phoneNum, setPhoneNum] = useState(_phoneNum)

    const areaCodeList = getAreaCodeList()

    useEffect(() => {
        const { _areaCode, _phoneNum } = getOrderedPhoneNumber(phoneNumber, countryCode)
        setAreaCode(_areaCode)
        setPhoneNum(_phoneNum)
    }, [countryCode, phoneNumber])

    useEffect(() => {
        handleChange()
    }, [areaCode, phoneNum])

    function getAreaCodeList() {
        return areaCodesJson.map((areaCode) => areaCode.dial_code)
    }

    function getAreaCodeByCountryCode(countryCode) {
        const areaCode = areaCodesJson.find((areaCode) => areaCode.code === countryCode)
        return areaCode?.dial_code || ''
    }
    
    function getOrderedPhoneNumber(phoneNumber, countryCode) {
        let [_areaCode, _phoneNum] = phoneNumber?.split('-')
        if(_areaCode && !_phoneNum) {//this is for the case when the phone number doesn't have an area code
            _phoneNum = _areaCode
            _areaCode = ''
        }
        if (!_areaCode) {
            _areaCode = getAreaCodeByCountryCode(countryCode)
        }
        return {_areaCode, _phoneNum}
    }

    const handleChange = () => {
        let fullPhoneNumber = `${areaCode}-${phoneNum}`
        if(!phoneNum) fullPhoneNumber = ''
        setPhoneNumber(fullPhoneNumber)
    }

    const areaCodeInput = () => {
        const options = areaCodeList.map((ac) => ({ value: ac, label: ac }) )
        const selectedAreaCode = options?.filter(ac => areaCode === ac.value)
        return  <div id='area-code-input' className='form-group row area-code-wrap'>
                    <ReactSelect
                        className='area-code-select'
                        options={options}
                        isClearable={false}
                        // placeholder={t('doctor.registration.placeholders.country')}
                        defaultValue={selectedAreaCode[0]}
                        onMenuClose={() => {}}
                        onChange={({value}) => {
                            setAreaCode(value)
                        }}
                    />
                </div>
    }


  
    return (
    <div id='phone-number-section' className='wrap-phone-number'>
        {areaCodeInput()}
        <input 
            className='form-control phone-number-input' 
            onInput={(e) => setPhoneNum(e.target.value)} 
            name='phoneNum' 
            value={phoneNum || ''} 
            id='phone-num-input'
        />
    </div>
  )
}

export default PhoneNumberInput