/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import TrashIcon from '../../../assets/img/icons/trash.svg'
import EditIcon from '../../../assets/img/icons/Edit.svg'
import CheckedIcon from '../../../assets/img/icons/Check.svg'
import ReminderClockIcon from '../../../assets/img/icons/reminder_clock.svg'
import { useSelector } from 'react-redux'
import { addOrUpdateMeasurementReminders, getPatientMeasurementReminders } from 'api/api-manager'
import './reminders.scss'
import ConfirmModal from 'components/Modals/confirm-modal'
import { Modal } from 'reactstrap'
import ReminderForm from './reminder-form'
import { t } from 'multi-language/multi-lang.service'

function MeasuremetReminders({ user, role = 'patient' }) {
    const patientId = user.id
    const [editReminderIndex, setEditReminderIndex] = useState()
    const [showDeleteWarn, setShowDeleteWarn] = useState()
    const [selectedReminders, setSelectedReminders] = useState([])
    const { patientsMeasurementReminders } = useSelector(state => state)
    const _reminders = patientsMeasurementReminders?.[patientId] || []
    const [reminders, setReminders] = useState(_reminders)
    const isDoctor = role === 'doctor'

    useEffect(() => {
        if (!patientsMeasurementReminders?.[patientId]) {
            getPatientMeasurementReminders(patientId)
        }
    }, [])

    useEffect(() => {
        if (!_reminders.length && !reminders.length) return
        setReminders(_reminders)
    }, [_reminders])

    const isSelected = (index) => selectedReminders.includes(index)
    const isSelectedAll = () => selectedReminders.length > 0 && selectedReminders.length === reminders.length

    const toggleSelect = (index) => {
        const newSelected = isSelected(index) ? selectedReminders.filter(rInd => rInd !== index) : [...selectedReminders, index]
        setSelectedReminders(newSelected)
    }

    const toggleSelectAll = () => {
        const newSelected = isSelectedAll() ? [] : reminders.map((m, i) => i)
        setSelectedReminders(newSelected)
    }

    const deleteReminders = (remindersToDelete) => {
        console.log('remindersToDelete', remindersToDelete)
        const updatedReminders = reminders?.map((r, i) => {
            if (remindersToDelete.includes(i)) {
                return { ...r, deleted: true }
            } else {
                return r;
            }
        });
        saveReminders(updatedReminders)
        closeEditModal()
        setShowDeleteWarn()
        setSelectedReminders([])
    };

    const closeEditModal = () => {
        setEditReminderIndex()
    }

    const handleCancel = () => {
        setReminders(_reminders)
        closeEditModal()
    }

    const updateReminder = (reminderIndex, updatedeFields) => {
        let updatedReminders = reminders?.map((r, i) =>
            i === editReminderIndex ? { ...r, ...updatedeFields } : r,
        );
        setReminders(updatedReminders);
    };

    const saveReminders = async (updatedReminders = reminders) => {
        await addOrUpdateMeasurementReminders(patientId, updatedReminders)
        closeEditModal()
    }

    const renderEditForm = () => {
        return (
            <Modal
                className="modal-dialog-centered"
                contentClassName=""
                isOpen={!!reminders?.[editReminderIndex]}
                toggle={() => closeEditModal()}
            >
                <>
                    <div className='wrap-reminder-form'>
                        <p className='reminder-form-title'>Adding reminder<small className='text-black'>Reminder will show in mobile app only</small></p>

                        <form className='reminders-form'>
                            <div className='form-group'>
                                <ReminderForm key={editReminderIndex} reminder={reminders[editReminderIndex]} reminderIndex={editReminderIndex} updateReminder={updateReminder} deleteReminders={deleteReminders} />
                            </div>
                        </form>
                        <div className="modal-footer p-0">
                            <button
                                className="btn cancel-btn"
                                type="button"
                                onClick={handleCancel}>Cancel</button>

                            <button
                                className="btn save-btn"
                                type="button"
                                onClick={() => saveReminders()}>Save</button>
                        </div>
                    </div>
                </>
            </Modal>
        )
    }

    const addReminder = () => {
        const initialReminder = { time: '00:00', days: '', is_alarm_on: true };
        const updatedReminders = [...reminders, initialReminder];
        setReminders(updatedReminders);
    };

    const getReminderRow = (reminderIndex, i) => {
        const reminder = reminders[reminderIndex]
        return (<tr id={`reminder-row-${i}`} key={reminder?.id} className={`${isSelected(reminderIndex) && 'selected'} last-row}`}>
            {!isDoctor && <td id={`check-reminder-${i}`} className='td-checkbox'>{<span onClick={() => toggleSelect(reminderIndex)} className='custom-checkbox'><img src={CheckedIcon} alt='checked' /></span>}</td>}
            <td className='td-hours td-reminder'>{reminder?.time}</td>
            <td className='td-frequency'><span className='days'>{reminder?.days?.length === 7 ? t('general_words.days.every') : reminder?.days?.split('')?.map(d => t(`general_words.days.${[d]}`)).join(' | ')}</span></td>
            {!isDoctor && <td className='td-edit'>{<img className='cursor-pointer' onClick={() => setEditReminderIndex(reminderIndex)} src={EditIcon} alt={t('general_words.edit')} />}</td>}
        </tr>)
    }

    const isEdit = () => {
        return !!reminders?.[editReminderIndex]
    }

    return (
        <div id='measurement-reminders' className='wrap-reminders-page'>
            {showDeleteWarn && <ConfirmModal
                text={`${t('pages.measurements.confirm_delete')}`}
                onConfirm={() => deleteReminders(selectedReminders)}
                onCancel={() => setShowDeleteWarn(false)}
                displayTopLine
                cancelButtonText={t('general_words.cancel')}
                confirmButtonText={t('general_words.delete')}
                subText={''}
                contentAlign='center'
                titleWheight='light'
            />}
            {isEdit() && renderEditForm()}
            <div className='top-section'>
                <span className='page-title'>
                    {!isDoctor && `${t('pages.measurements.edit')} `}{t('pages.measurements.full_name')}
                    {!isDoctor && <span>{t('pages.measurements.sync')}</span>}
                </span>
                {!isDoctor && <div className='reminders-actions'>
                    <button id='add-reminder' onClick={() => addReminder()} className='btn add-medicine'>+ {t('pages.measurements.add_action')}</button>
                    <button id='delete-reminders' disabled={!selectedReminders?.length} onClick={() => setShowDeleteWarn(true)} className='btn delete-medicine'>
                        {t('general_words.delete')}
                        <span className='pl-3'><img alt='delete' src={TrashIcon} /></span>
                    </button>
                </div>}
                <div className='reminders-table-wrap'>
                    <table className='table reminders-table measurements w-75'>
                        {reminders?.length > 0 &&
                            <thead>
                                <tr>
                                    {!isDoctor && <th className='td-checkbox'><span onClick={toggleSelectAll} className='custom-checkbox'>{isSelectedAll() && <img src={CheckedIcon} alt='checked' />}</span></th>}
                                    <th className='td-reminder'>{t('general_words.hours')}<span className='info-flag mx-1'>?<span className='info-text'>{t('pages.measurements.mobile_only')}</span></span></th>
                                    <th className='td-frequency'>{t('pages.measurements.frequency')}</th>
                                    {!isDoctor && <th className='td-edit'>{t('general_words.edit')}</th>}
                                </tr>
                            </thead>
                        }
                        <tbody>
                            {reminders?.length ?
                                reminders.map((m, i) => getReminderRow(i))
                                :
                                <tr>
                                    <td colSpan={5} className='empty-reminders-section'>
                                        <img className='mb-2' src={ReminderClockIcon} alt='clock reminder' />
                                        <p>{t(`pages.measurements.${!isDoctor ? 'add_action' : 'no_reminders'}`)}</p>
                                    </td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default MeasuremetReminders