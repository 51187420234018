import axios from 'axios'
import { VALID_PASSWORD_REGEX } from 'consts'
import React,{useState} from 'react'
import { useLocation } from 'react-router-dom'
import { Container,Row,Col, Button, InputGroup, Input } from 'reactstrap'
import PencilIcon from '../../../assets/img/icons/Edit.svg'
import PassShowIcon from '../../../assets/img/icons/Eye-show.svg'
import PassHideIcon from '../../../assets/img/icons/Eye-hide.svg'
import { t } from 'multi-language/multi-lang.service'
import PasswordRestrictions from 'pages/login/password-restrictions'

export default function Passwords({user,jwtToken}) {
    const location = useLocation()
    const [toggleAccountPassword, setToggleAccountPassword] = useState(location?.state?.isOpen)
    const [currentPassword, setCurrentPassword] = useState({value:'',show:0})
    const [newPassword, setNewPassword] = useState({value:'',show:0})
    const [confirmPassword, setConfirmPassword] = useState({value:'',show:0})
    const [statusMsg, setStatusMsg] = useState()
    const errorMsg = (label)=>{
      if(label === 'confirm'){
        if(confirmPassword.value && newPassword.value){
          return confirmPassword.value !== newPassword.value && t('pages.passwords.no_match')
        }
        return null
      }
      if(label === 'new'){
        if(newPassword.value && !VALID_PASSWORD_REGEX.test(newPassword.value)) 
          return t('pages.passwords.invalid')
      }
    }

    const handleSave= async ()=>{
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/app/user/change-password`,
        {userId:jwtToken? user.userId:user.data.userId, currentPassword:currentPassword.value, newPassword:newPassword.value},
        { headers: {'Authorization': `Bearer ${jwtToken? jwtToken : user.jwtToken}` } }
      ).catch(err=>{
        const {data,status} = err.response
        const {message} = data
        setStatusMsg({message,status})
        }); 
      if(res){
        setStatusMsg({message: t('pages.passwords.changed'), status:200})
      } 
    }

    const isFormValid=
      currentPassword.value && newPassword.value && confirmPassword.value 
      && newPassword.value === confirmPassword.value
      && VALID_PASSWORD_REGEX.test(newPassword.value)

    const renderPasswordField = (label, passwordValue, setPasswordValue) =>
    <Row className="align-items-center" style={{width:'50%',marginTop:'20px'}}>
      <Col>
        <label>{t(`pages.passwords.${label}`)}</label>
        <small style={{display:'block',color:'#f5365c'}} >{errorMsg(label)}</small>
      </Col>
      <Col xl="auto" style={{padding:0}} >
        <InputGroup>
          <Input
            id={`input-${label}-password`}
            value={passwordValue.value}
            onChange ={e=>setPasswordValue(Object.assign({},passwordValue,{value:e.target.value}))}
            type={passwordValue.show?"text":'password'}
          />
          <div className='input-group-append'>
            <div className='input-group-text'>
            <img alt="eye" src={passwordValue.show ? PassHideIcon : PassShowIcon}
                  onClick={()=>setPasswordValue(Object.assign({},passwordValue,{show:1-passwordValue.show}))}
                  style={{height:'14px', cursor:'pointer'}} /> 
            </div>
          </div>
        </InputGroup>
      </Col>
    </Row> 

    const EditButton = ({onClick})=>
    <img alt="edit"
        id='edit-password'
        src={PencilIcon}
        onClick={onClick} 
        width="20px" 
        style={{cursor:'pointer',marginLeft:'10px',marginBottom:'5px'}}
      /> 

    return (
        <Container id='password-page' fluid>
            <div className="profile-content-container">
                <div style={{marginLeft:'55px',marginTop:'40px',marginBottom:'40px',color:'black',fontWeight:200}}>
                  <Row className="info-row-header" style={{width:'50%'}} >
                    <Col style={{padding:0}} >{t('pages.passwords.account_pass')}</Col>
                    <Col xl="auto"> 
                    ****** 
                    <EditButton onClick={()=>setToggleAccountPassword(!toggleAccountPassword)}/> 
                    </Col>
                  </Row>
                  <div style={{display:toggleAccountPassword?'block':'none'}} >
                      {renderPasswordField('current', currentPassword, setCurrentPassword)}
                      {renderPasswordField('new', newPassword, setNewPassword)}
                      {renderPasswordField('confirm', confirmPassword, setConfirmPassword)}
                  
                      <div style={{width:'50%',marginTop:'30px',borderBottom:'2px solid #DCDCDC',paddingBottom:'20px'}} >
                        <PasswordRestrictions />
                      </div>

                      <div style={{display:'flex',width:'49.4%',marginTop:'20px'}}>
                        {statusMsg&& <div style={{color:statusMsg.status===200?'#2dce89':'#f5365c',fontWeight:400}} >
                          {statusMsg.message}
                        </div>}
                        <Button id='save-password' onClick={handleSave} disabled={!isFormValid} className="text-white" style={{padding:'0.425rem 1.05rem',backgroundColor:'#3873AE',marginLeft:'auto'}}>
                          {t('general_words.save')}
                        </Button>
                      </div>

                    </div>
            </div>
        </div>
        </Container>
    )
}
