/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { addFotaToCountry, deleteFota, getCountriesAndConfig, getFotaList, removeFotaFromCountry } from 'api/admin-api'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import FotaForm, { DEVICE_TYPES } from './fota-form'
import { setFotaList } from 'store/actions'

function Fota() {
    const { countries, fotaList } = useSelector(state => state)
    console.log('fotaList', fotaList)
    const [highestGeneralFota, setHighestGeneralFota] = useState()
    const [isAddingFota, setIsAddingFota] = useState()
    const [disabledCountries, setDisabledCountries] = useState()
    const [fotaDeviceDisplay, setFotaDeviceDisplay] = useState('all')
    const dispatch = useDispatch()

    const fetchData = async () => {
        if (!countries.length) await getCountriesAndConfig()
        if (!fotaList.length) await getFotaList()
    }

    useEffect(() => {
        fetchData()
    }, [])

    const handleDeleteFota = async (fota) => {
        const confirm = window.confirm(`Are you shure that you want to delete fota version: ${fota.fw_version}`)
        if(!confirm) return
        await deleteFota(fota.id)
        const updatedFotaList = fotaList.filter(f => f.id !== fota.id)
        dispatch( setFotaList(updatedFotaList) )
    }

    const renderPublicCountries = (fota) => {
        const {countries: selectedCountriesCodes, id: fotaId } = fota
        const options = countries?.map((c) => { return { value: c.code, label: c.name, id: c.id } })
        options.unshift({ value: null, label: 'All countries', id: 99999 })
        const selectedCountries = options?.filter(c => selectedCountriesCodes.includes(c.value))
        return <Select options={options}
                    isDisabled={disabledCountries}
                    isClearable={false}
                    placeholder={!selectedCountries.length ? 'NOT PUBLIC' : ''}
                    isMulti
                    defaultValue={selectedCountries}
                    onChange={(selectedElements) => {
                        console.log('selectedElements', selectedElements)
                        updateCountryFota(selectedElements, selectedCountries, fotaId)
                    }} 
                />
    }

    const updateCountryFota = async (newSelectedCountries, oldSelectedCountries, fotaId) => {
        setDisabledCountries(true)
        const mapNewSelected = {}
        const mapOldSelected = {}
        newSelectedCountries.forEach(c => {
            mapNewSelected[c.value] = c
        });
        oldSelectedCountries.forEach(c => {
            mapOldSelected[c.value] = c
        });

        if(newSelectedCountries > oldSelectedCountries){//added country
            let countryToAdd = newSelectedCountries.find(c => !mapOldSelected[c.value])
            let confirm = true
            if(countryToAdd?.value ) {
                confirm = window.confirm('be noticed that choosing specific country means that users from this country will receive the highest fota by their country even if it will be a higher fota available for all countries')
                if(!confirm) return setDisabledCountries(false)
            }
            await addFotaToCountry(fotaId, countryToAdd?.value)
        } else {//deleted country
            let countryToDelete = oldSelectedCountries.find(c => !mapNewSelected[c.value])
            await removeFotaFromCountry(fotaId, countryToDelete?.value)
        }
        const countryCodes = newSelectedCountries.map(c => c.value)
        const updatedFotaList = fotaList.map(f => f.id === fotaId ? {...f, countries: countryCodes} : f)
        dispatch( setFotaList(updatedFotaList) )
        setDisabledCountries(false)
    }
    if(isAddingFota){
        return(
            <div className='card-body'>
                <FotaForm backToList={setIsAddingFota} />
            </div>
        )
    }

    const filteredFotaList = () => {
        if(fotaDeviceDisplay === 'all') return fotaList
        return fotaList.filter(f => f.device_type === fotaDeviceDisplay)
    }

    const renderFotaFilterSelect = () => {
        const options = Object.values(DEVICE_TYPES)?.map((dType) => { return { value: dType, label: dType, id: dType } })
        options.unshift({ value: 'all', label: 'All devices', id: 'all' })
        return(
            <div className='w-25 form-group'>
                <label>Filter Fota list by device:</label>
                <Select
                    className='w-50'
                    options={options}
                    defaultValue={options.filter(o => o.value === fotaDeviceDisplay)}
                    onChange={(selectedElement) => {
                        setFotaDeviceDisplay(selectedElement?.value)
                    }} 
                />
            </div>
        )
    }

    return (
        <div className='card-body admin-fota-list'>
            <div className='wrap-config'>
                <div className='list-group'>
                    <button onClick={() => setIsAddingFota(true)} className='btn btn-primary mb-3'>Add Fota</button>
                    {renderFotaFilterSelect()}
                    {
                        filteredFotaList()?.map(f => (
                            <div key={f.id} className='list-group-item d-flex justify-content-between countries-row'>
                                <p className='col-1 mb-0 row align-items-center'><strong className='pr-2'>ID:</strong><span>{f.id}</span></p>
                                <p className='col mb-0 row align-items-center'><strong className='pr-2'>FW Version:</strong><span>{f.fw_version}</span></p>
                                <p className='col mb-0 row align-items-center'><strong className='pr-2'>Device type:</strong><span>{f.device_type}</span></p>
                                <p className='col-4 mb-0 row align-items-center'><strong className='pr-2'>Public:</strong><strong className='w-75'>{renderPublicCountries(f)}</strong></p>
                                <p className='col mb-0 row align-items-center'><strong className='pr-2'>From app version:</strong><span>{f.from_app_version || 'All'}</span></p>
                                <p className='col mb-0 row align-items-center'><strong className='pr-2'>To app version:</strong><span>{f.to_app_version || 'All'}</span></p>
                                <p className='col-1 mb-0 row align-items-center'><button onClick={() => handleDeleteFota(f)} className='p-2 rounded btn btn-outline-danger'>&#x274c;&#xfe0e;</button></p>
                            </div>
                        ))
                    }
                </div>
            </div>
        </div>

    )
}

export default Fota