import React from 'react'
import './radio-button.component.scss'

function RadioButtonComponent({name, options, checkedOption, onChange}) {
    const isChecked = (key) => (key === checkedOption)
    return (
        <div id={`radio-input-${name}`} className='wrap-radio-input'>
            {options.map(op => <div key={op.key} className={`form-check mb-3 ${isChecked(op.key) && 'active' }`}>
                <input id={`radio-option-${op.key}`} onChange={() => onChange(op.key, name)} className={`form-check-input`} type="radio" name={name} checked={isChecked(op.key)} />
                <label className="form-check-label" htmlFor={op.key}>
                    {op.label}.
                </label>
            </div>)}
        </div>
    )
}

export default RadioButtonComponent