import React, { useEffect, useState } from 'react'
import { Col, Container, Row } from 'reactstrap'
import { renderThreshold } from 'utils/formaters'
import ThresholdModal from 'components/Modals/Threshold'
import axios from 'axios'
import Cookies from 'js-cookie'
import { useDispatch, useSelector } from 'react-redux'
import { getRefreshedUserData } from 'api/api-manager'
import { setUserToState } from 'store/actions'
import PencilIcon from '../../../assets/img/icons/Edit.svg'
import { t } from 'multi-language/multi-lang.service'
import { convertTextToSnakeCase } from 'utils'

export default function Thresholds({ patient, jwtToken, role = 'patient' }) {
    const stateUser = useSelector(state => state.user.data)
    const user = role === 'doctor' ? patient : stateUser
    const [data, setData] = useState(user.thresholds)
    const [selectedThreshold, setSelectedThreshold] = useState()
    const dispatch = useDispatch()

    const units = {
        blood_pressure: t('measurements.blood_pressure.unit'),
        pulse_rate: t('measurements.pulse_rate.unit'),
        respiratory_rate: t('measurements.respiratory_rate.unit'),
        spo2: '%',
        body_temperature: t(`measurements.body_temperature.${user.temperatureUnit === '°C' ? 'c' : 'f'}_unit`),
        weight: t(`measurements.weight.${user.weightUnit}_unit`),
        glucose: t(`measurements.glucose.${user.glucoseUnit === 'mg/dl' ? 'mgdl': 'mmol'}_unit`),
    }

    useEffect( () => {
        // i think this function is unnecessary but it was asked by Menni - it will reload patient data because maybe the doctor changed patients data
        if (role === 'doctor') return 
        getRefreshedUserData()
    },[role])

    const handleSave = (threshold, values, secValues) => {
        if(role === 'doctor'){
            let updatedBloodPressureSys 
            let updatedBloodPressureDia 
            if ((data[threshold].low !== values[0] || 
                data[threshold].high !== values[1] ||  
                data[threshold].low_SYS !== values[0] ||
                data[threshold].high_SYS !== values[1] ||
                data[threshold].low_DIA !== secValues[0] ||
                data[threshold].high_DIA !== secValues[1])) {                   
                 if(data[threshold].low_SYS !== values[0] ||
                      data[threshold].high_SYS !== values[1] ){
                         updatedBloodPressureSys = 'SYS'
                       }
                         if ( data[threshold].low_DIA !== secValues[0] ||
                            data[threshold].high_DIA !== secValues[1]){
                                updatedBloodPressureDia = 'DIA'
                            }
                try {
                    axios.post(`${process.env.REACT_APP_API_URL}/app/user/doctor-update-thresholds`,{
                     userId:stateUser.userId,
                     units: units[convertTextToSnakeCase(threshold)],
                     doctorName:`${stateUser.firstName} ${stateUser.lastName}`,
                     patientName: `${user.firstName} ${user.lastName}`,
                     patientId: user.id,
                     patientUserId: user.userId,
                     threshold: threshold,
                     values: values,
                     secValues: updatedBloodPressureDia === 'DIA' ? secValues : null, 
                     updatedBloodPressureSys:updatedBloodPressureSys,
                     updatedBloodPressureDia:updatedBloodPressureDia 
                    },
                    { headers: { 'Authorization': `Bearer ${jwtToken}` } })
                } catch (err) {
                    console.log('error send update thresholds mail', err)
                }

            }
        }
        
        const newData = { ...data }
        if (threshold === 'Blood pressure') {
            newData[threshold].low_SYS = values[0]
            newData[threshold].high_SYS = values[1]
            newData[threshold].low_DIA = secValues[0]
            newData[threshold].high_DIA = secValues[1]
        }
        else {
            newData[threshold].low = values[0]
            newData[threshold].high = values[1]
        }

        try {
            axios.post(`${process.env.REACT_APP_API_URL}/app/${role}/update-tresholds`,
                {
                    patientId: user.id,
                    thresholds: JSON.stringify(newData),
                    weightUnit: user.weightUnit,
                    glucoseUnit: user.glucoseUnit,
                    tempUnit: user.temperatureUnit,
                },
                { headers: { 'Authorization': `Bearer ${jwtToken}` } }
            )
            setData(newData)
            if(role === 'patient'){
                const updatedUser = Object.assign({}, user, { thresholds: newData })
                dispatch ( setUserToState({data: updatedUser}) )
                Cookies.set('user', JSON.stringify({ data: updatedUser, type: 'patient', jwtToken }), { expires: 1 / 24 })
            }
        }
        catch {
            console.log('error update-tresholds')
        }

        setSelectedThreshold()
        values=''
    }
    return (
        <div id='thresholds-page'>
            {
                selectedThreshold &&
                <ThresholdModal data={selectedThreshold} toggleModal={setSelectedThreshold} onSave={handleSave} />
            }
            <Container fluid>
                <div className="profile-content-container">
                    <div style={{ marginLeft: '55px', marginTop: '40px', marginBottom: '40px' }}>
                        <Row className="info-row-header" >
                            <Col >{t('pages.thresholds.threshold_name')}</Col>
                            <Col >{t('pages.thresholds.page_name')}</Col>
                            <Col xl="1" >{t('general_words.edit')}</Col>
                        </Row>
                        {Object.keys(data).map(threshold => {
                            const key = convertTextToSnakeCase(threshold)
                            return <Row id={`threshold-${key}`} key={threshold} className="info-row" >
                                <Col>{t(`measurements.${key}.title`)}</Col>
                                <Col> {renderThreshold(threshold, data[threshold], units[key])} </Col>
                                <Col xl="1" >
                                    <img alt="edit"
                                        id={`edit-${threshold}`}
                                        src={PencilIcon}
                                        onClick={() => setSelectedThreshold({ threshold, values: data[threshold], unit: units[key] })}
                                        width="20px"
                                        style={{ cursor: 'pointer' }} />
                                </Col>
                            </Row>
                })}
                    </div>
                </div>
            </Container>
        </div>
    )
}

